import { useTranslation } from 'react-i18next';
import ShiftProsPresenter from './ShiftDescriptionPros.Presenter';
import DotMenu, { DotMenuProps } from 'components/DotMenu';
import { DateTime } from 'luxon';
import ReactPaginate from 'react-paginate';
import { ApplicantStatus } from 'pages/shift/core/Modules/Users/Domain/Entities/ApplicantShift.entity';
import SheetProfile from 'components/SheetProfile';
import Button from 'components/Button';

const ShiftDescriptionPros = () => {
  const [t] = useTranslation(['Shifts']);
  const presenter = ShiftProsPresenter();

  const ComponentConf = presenter.Confirmation;
  return (
    <div className="sm:mx- mx-2">
      <p className="text-xl font-semibold pb-3">Pros</p>

      <div className="flex flex-wrap justify-between">
        {presenter.query.data?.applicantShift.shiftApplicants.map((e) => {
          const isPaidCancelled = ApplicantStatus.PAID === e.status && !e.clockIn && !e.clockOut;
          const isNowShow = e.shiftFinished && !e.clockIn;

          const options: DotMenuProps = {
            data: [],
          };

          if (!e.shiftFinished) {
            options.data.push({
              displayName: t('proList.reject'),
              func: () => presenter.onRejectApplicant(e.applicationId),
            });
          }

          if (!e.isPreferred) {
            options.data.push({
              displayName: t('proList.addFavorites'),
              func: () => presenter.onAddPreferredUser(e.userId),
            });
          }

          if (!e.isBlocked) {
            options.data.push({
              displayName: t('proList.blockUser'),
              func: () => presenter.onAddBlockedUser(e.userId, e.applicationId),
            });
          }

          return (
            <>
              {!e.isBlocked && (
                <div
                  key={e.userId}
                  className={`flex  sm:flex-nowrap cursor-pointer justify-center sm:justify-between ${
                    (isPaidCancelled || isNowShow) && 'bg-neutral-400'
                  } ${
                    e.userId === presenter.selectedPro?.userId
                      ? 'border-secondary-500'
                      : 'border-neutral-400 '
                  } border-[0.5px] rounded-xl p-4 w-full sm:w-10/12 lg:w-[46%] ml-4 mt-2`}
                >
                  <div className="flex w-full md:w-1/2" onClick={() => presenter.setSelectedPro(e)}>
                    <div>
                      <img
                        src={e.userImage}
                        className="md:w-[46px] md:h-[46px] h-[30px] w-[30px] object-cover rounded-full max-w-3xl"
                      />
                    </div>

                    <div className="ml-3 text-primary ">
                      <p className="text-[15px] font-semibold truncate">{e.name}</p>
                      <p className="text-[11px] font-normal">
                        {t('proList.clockIn')}:{' '}
                        {e.clockIn
                          ? DateTime.fromISO(e.clockIn).toLocaleString(DateTime.TIME_SIMPLE)
                          : '--:--'}
                      </p>
                      <p className="text-[11px] font-normal">
                        {t('proList.clockOut')}:
                        {e.clockOut
                          ? DateTime.fromISO(e.clockOut).toLocaleString(DateTime.TIME_SIMPLE)
                          : '--:--'}
                      </p>
                    </div>
                  </div>

                  <div
                    className="flex justify-center text-center sm:text-left sm:justify-end items-center w-1/3"
                    onClick={() => presenter.setSelectedPro(e)}
                  >
                    {isPaidCancelled ? (
                      <span className="text-warnings-500 font-semibold">
                        {t('proList.cancelledWith')} (${e.totalCost})
                      </span>
                    ) : (
                      <p className="mr-4 text-[11px] text-primary">
                        {isNowShow ? (
                          <>
                            <span className=" text-primary text-[13px] font-semibold">NO SHOW</span>
                          </>
                        ) : (
                          <>
                            {e.status === ApplicantStatus.ACCEPTED && 'Est. '}{' '}
                            {t('proList.totalCost')}: <br />
                            <span className="text-secondary-500 text-[13px] font-semibold">
                              {e.status === ApplicantStatus.ACCEPTED
                                ? e.estTotalCost.toFixed(2)
                                : e.totalCost?.toFixed(2)}
                            </span>
                          </>
                        )}
                      </p>
                    )}
                  </div>

                  <div className="w-1/12 text-right">
                    <DotMenu data={options.data} />
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>

      {presenter.selectedPro && (
        <SheetProfile
          selected={presenter.selectedPro !== undefined}
          idProfile={presenter.selectedPro.userId}
          setSelected={() => presenter.setSelectedPro(undefined)}
          actionButtons={
            <div className="flex mt-3 justify-around flex-shrink-0 w-full">
              {!presenter.selectedPro.isPreferred && (
                <Button
                  onClick={() => presenter.onAddPreferredUser(presenter.selectedPro?.userId ?? '')}
                  name={t('proList.addFavorites')}
                  size="xs"
                  color="outlineSecondary"
                  rounded="md"
                />
              )}
              {!presenter.selectedPro.shiftFinished && (
                <Button
                  name={t('proList.reject')}
                  onClick={() =>
                    presenter.onRejectApplicant(presenter.selectedPro?.applicationId ?? '')
                  }
                  size="xs"
                  color="primary"
                  rounded="md"
                />
              )}
              {!presenter.selectedPro.isBlocked && (
                <Button
                  name={t('proList.blockUser')}
                  size="xs"
                  color="danger"
                  onClick={() =>
                    presenter.onAddBlockedUser(
                      presenter.selectedPro?.userId ?? '',
                      presenter.selectedPro?.applicationId ?? ''
                    )
                  }
                  rounded="md"
                />
              )}
            </div>
          }
        />
      )}

      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={presenter.handlePageClick}
        pageRangeDisplayed={15}
        pageCount={Math.ceil((presenter.query.data?.applicantShift.totalItems ?? 0) / 15)}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName={'flex justify-center mt-10 items-center mb-5'}
        pageLinkClassName={'text-base text-secondary-500 px-3 py-2 rounded-lg mx-1'}
        previousLinkClassName={'text-secondary-500 px-3 py-2 rounded-lg mx-1'}
        nextLinkClassName={'text-secondary-500 px-3 py-2 rounded-lg mx-1'}
        activeLinkClassName={'text-white bg-secondary-500'}
      />

      <ComponentConf />
    </div>
  );
};

export default ShiftDescriptionPros;
